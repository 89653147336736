import { FlexRow } from '@/swell-ui/FlexRow'
import { ThemeData } from '@/swell-ui/theme/branding'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import styled from 'styled-components'
import { paragraphHintCSS } from '../../styles/common'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import React, { ReactNode } from 'react'
import { AirdropRewardSummary, VestingClaimSchedule } from '../../types'
import { css } from 'styled-components'
import { StyledButtonThin } from '../../AirdropButtons'

const LOGO_SIZE_PX = 27

export function AirdropClaimAccounting({
  rewardSummary,
  vestingSchedule,
  justClaimed,
}: {
  rewardSummary: AirdropRewardSummary
  vestingSchedule: VestingClaimSchedule
  justClaimed: boolean
}) {
  return (
    <Layout gap="48">
      <TotalAllocation rewardSummary={rewardSummary} />
      {Boolean(vestingSchedule.vesting.length) && (
        <VestedAllocation
          vestingSchedule={vestingSchedule}
          justClaimed={justClaimed}
        />
      )}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  flex-flow: row wrap;
  align-items: flex-start;

  color: var(--Swell-White-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  .value {
    color: var(--Swell-White-50, #fff);
    /* Body/large bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
  }

  .hint {
    ${paragraphHintCSS}
    height: 29px;
  }

  > * + * {
    margin-right: 48px;
  }
`

function TotalAllocation({
  rewardSummary,
}: {
  rewardSummary: AirdropRewardSummary
}) {
  const { baseReward, loyaltyBonus, totalClaimable } = rewardSummary

  return (
    <AllocLayout>
      <ColoredHeadingTypography>Total allocation</ColoredHeadingTypography>
      <span>Base Reward</span>
      <TokenAmount amount={baseReward} logoURI={TOKEN_LIST_SWELL.logoURI} />
      <span>Loyalty Bonus</span>
      <TokenAmount amount={loyaltyBonus} logoURI={TOKEN_LIST_SWELL.logoURI} />
      <HDivider />
      <span>Total Claimable</span>
      <TokenAmount amount={totalClaimable} logoURI={TOKEN_LIST_SWELL.logoURI} />
    </AllocLayout>
  )
}

function VestedAllocation({
  vestingSchedule,
  justClaimed,
}: {
  vestingSchedule: VestingClaimSchedule
  justClaimed: boolean
}) {
  const {
    claimableNowNonRestaked,
    claimableNowRestaked,
    nonRestakedLogoURI,
    restakedLogoURI,
    vesting,
    selectedOption2,
  } = vestingSchedule

  const scheduleNodes: ReactNode[] = []
  for (const { date, amount, tokenLogoURI, claimed } of vesting) {
    scheduleNodes.push(
      <span className="hint" key={date}>
        {date}
      </span>
    )
    scheduleNodes.push(
      <TokenAmount
        key={`${date}-amt`}
        amount={amount}
        logoURI={tokenLogoURI}
        claimed={!justClaimed && claimed}
      />
    )
  }

  const claimableNodes: ReactNode[] = []
  claimableNodes.push(<span key="claimable">Claimable</span>)

  if (selectedOption2) {
    const shouldShowButton =
      !justClaimed && Boolean(vestingSchedule.vesting?.[0]?.claimed)

    if (claimableNowNonRestaked && claimableNowNonRestaked !== '0') {
      claimableNodes.push(
        <div key="claimable-now-restaked" className="colr">
          <TokenAmount
            amount={claimableNowNonRestaked}
            logoURI={nonRestakedLogoURI}
          />
        </div>
      )
    }
    if (claimableNowRestaked) {
      if (claimableNowRestaked === '0' && claimableNowNonRestaked !== '0') {
        claimableNodes.push(null)
      } else {
        claimableNodes.push(
          <div key="claimable-now-non-restaked" className="colr rs">
            <TokenAmount
              amount={claimableNowRestaked}
              logoURI={restakedLogoURI}
            />
            {shouldShowButton && (
              <StyledButtonThin disabled>Claim</StyledButtonThin>
            )}
          </div>
        )
      }
    }
  } else {
    if (claimableNowNonRestaked) {
      claimableNodes.push(
        <div key="claimable-now-restaked" className="colr">
          <TokenAmount
            amount={claimableNowNonRestaked}
            logoURI={nonRestakedLogoURI}
          />
        </div>
      )
    }
    if (claimableNowRestaked && claimableNowRestaked !== '0') {
      claimableNodes.push(
        <div key="claimable-now-non-restaked" className="colr">
          <TokenAmount
            amount={claimableNowRestaked}
            logoURI={restakedLogoURI}
          />
        </div>
      )
    }
  }

  const topClaimableNode1 = claimableNodes.shift()
  const topClaimableNode2 = claimableNodes.shift()
  const bottomClaimableNode1 = claimableNodes.shift()
  const bottomClaimableNode2 = claimableNodes.shift()

  const isTwoClaimableRows = Boolean(
    bottomClaimableNode1 || bottomClaimableNode2
  )

  return (
    <AllocLayout isTwoClaimableRows={isTwoClaimableRows}>
      <ColoredHeadingTypography>Vesting schedule</ColoredHeadingTypography>
      {scheduleNodes}
      <HDivider />
      <div className="below-line">
        {topClaimableNode1}
        {topClaimableNode2}

        {isTwoClaimableRows && (
          <div className="r2">
            {bottomClaimableNode1}
            {bottomClaimableNode2}
          </div>
        )}
      </div>
    </AllocLayout>
  )
}

const AllocLayout = styled.div<{ isTwoClaimableRows?: boolean }>`
  width: 100%;
  @media (min-width: 376px) {
    width: 256px;
  }

  ${ColoredHeadingTypography} {
    margin-bottom: 24px;
    grid-column: 1 / -1;
  }

  white-space: nowrap;

  .colr {
    grid-column: 2;
  }

  &,
  .below-line,
  .below-line > .r2 {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    align-items: center;
    grid-column-gap: 23px;
    grid-row-gap: 9px;
  }

  .below-line,
  .below-line > .r2 {
    grid-column: 1 / -1;
    width: 100%;
    @supports (display: contents) {
      display: contents;
    }
  }

  .rs {
    position: relative;
    button {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%) translateY(-2px) translateX(4px);

      min-width: 124px;
    }
  }

  .token-amt {
    position: relative;

    .claimed {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%) translateY(-2px) translateX(4px);
    }
  }

  /* ${({ isTwoClaimableRows }) =>
    isTwoClaimableRows &&
    css`
      margin-bottom: calc(-29.56px - 9px);
    `} */
`

const HDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${ThemeData.Swell.SwellLightBlue};
  grid-column: 1 / -1;
`

function TokenAmount({
  amount,
  logoURI,
  claimed,
}: {
  amount: string | undefined
  logoURI: string
  claimed?: boolean
}) {
  return (
    <FlexRow gap="6" justify="start" className="token-amt">
      <img src={logoURI} width={LOGO_SIZE_PX} height={LOGO_SIZE_PX} />
      <AsyncDiv loading={!amount}>
        {() => <span className="value">{amount}</span>}
      </AsyncDiv>
      {claimed && <span className="hint claimed">Claimed</span>}
    </FlexRow>
  )
}
