import { AirdropResult } from '@/state/dao/types'
import { merkleClaimable } from '@/util/merkledrop'
import { BigNumber } from 'ethers'
import { airdropOption1Vesting, airdropOption2Vesting } from '@/util/vesting'

export function claimableAmountForSlider({
  airdropResult,
  justClaimed,
}: {
  airdropResult: AirdropResult
  justClaimed: boolean
}) {
  if (!airdropResult.exists) {
    return BigNumber.from(0)
  }

  const totalAmount = airdropResult.data.totalAmount

  const { claimableAmount: trueClaimableAmount } = merkleClaimable({
    cumulativeAmount: airdropResult.data.cumulativeAmount,
    cumulativeClaimed: airdropResult.cumulativeClaimed,
    totalAmount,
  })

  if (trueClaimableAmount.gt(0)) {
    return trueClaimableAmount
  }

  if (!justClaimed) {
    // TODO: only supports day 1. Future vesting not supported yet.
    let firstVestingSize: BigNumber
    if (airdropResult.selectedOption2) {
      ;({ firstVestingSize } = airdropOption2Vesting({
        totalAmount: airdropResult.data.totalAmount,
        loyaltyAmount: airdropResult.loyaltyAmount,
      }))
    } else {
      ;({ firstVestingSize } = airdropOption1Vesting({
        totalAmount: airdropResult.data.totalAmount,
        vestingTier: airdropResult.vestingTier,
        loyaltyAmount: airdropResult.loyaltyAmount,
      }))
    }

    return firstVestingSize
  }

  let initialClaimed: BigNumber

  if (airdropResult.selectedOption2) {
    const { firstVestingSize, secondVestingSize } = airdropOption2Vesting({
      totalAmount: airdropResult.data.totalAmount,
      loyaltyAmount: airdropResult.loyaltyAmount,
    })

    initialClaimed = totalAmount.sub(firstVestingSize).sub(secondVestingSize)
  } else {
    const { firstVestingSize, secondVestingSize, thirdVestingSize } =
      airdropOption1Vesting({
        totalAmount: airdropResult.data.totalAmount,
        vestingTier: airdropResult.vestingTier,
        loyaltyAmount: airdropResult.loyaltyAmount,
      })

    initialClaimed = totalAmount
      .sub(firstVestingSize)
      .sub(secondVestingSize)
      .sub(thirdVestingSize)
  }

  return initialClaimed
}

export function shouldDisplaySplitter({
  airdropResult,
  justClaimed,
}: {
  airdropResult: AirdropResult
  justClaimed: boolean
}) {
  if (!airdropResult.exists) {
    return false
  }

  const { claimableAmount: trueClaimableAmount } = merkleClaimable({
    cumulativeAmount: airdropResult.data.cumulativeAmount,
    cumulativeClaimed: airdropResult.cumulativeClaimed,
    totalAmount: airdropResult.data.totalAmount,
  })

  if (trueClaimableAmount.gt(0)) {
    return true // TODO: only works on day 1
  }

  if (airdropResult.selectedOption2) {
    if (justClaimed) {
      return true
    }

    return false
  }

  return true
}
