import { CumulativeMerkleDrop__factory, Multicall3 } from '@/abis/types'
import { BigNumber, ethers } from 'ethers'
import { getAddress, hexZeroPad } from 'ethers/lib/utils'
import { IAirdropPersistenceApi, AirdropResult } from './types'
import { MerkleContractsState, MerkleDrop } from '@/types/merkle'
import {
  StaticAirdropResult,
  IStaticAirdropService,
} from '@/services/StaticAirdrop/types'
import { reconstructAirdropFromStaticData } from './util'
import { WalletClient } from '@/services/V3BackendService/hooks'
import { VoyageSignaturesService } from '@/services/VoyageSignatures'
import { VoyageSignatureOption } from '@/services/VoyageSignatures/types'

const DEFAULT_CACHE_TTL_MS = 1000 * 60 * 5 // 5 minutes

export class StaticAirdropFetcher {
  private merkleDropAirdropAddress: string
  private persistenceApi: IAirdropPersistenceApi
  private staticAirdropService: IStaticAirdropService
  private multicall: Multicall3
  private contractStateCacheTTLMs: number
  constructor({
    merkleDropAirdropAddress,
    persistenceApi,
    staticAirdropService,
    multicall,
    contractStateCacheTTLMs = DEFAULT_CACHE_TTL_MS,
  }: {
    merkleDropAirdropAddress: string
    persistenceApi: IAirdropPersistenceApi
    staticAirdropService: IStaticAirdropService
    multicall: Multicall3
    contractStateCacheTTLMs?: number
  }) {
    this.merkleDropAirdropAddress = merkleDropAirdropAddress
    this.persistenceApi = persistenceApi
    this.staticAirdropService = staticAirdropService
    this.multicall = multicall
    this.contractStateCacheTTLMs = contractStateCacheTTLMs
  }

  private userToStaticResult = new Map<string, StaticAirdropResult>()
  private contractsState: MerkleContractsState | null = null
  private lastContractsStateFetch = 0

  fetchAll = async (
    account: string
  ): Promise<{
    airdrop: AirdropResult
    contractsState: MerkleContractsState
  }> => {
    account = getAddress(account)
    const persistedClaim = this.persistenceApi.persistedClaimForAddress(account)

    const now = Date.now()

    let contractsState = this.contractsState
    let staticResult = this.userToStaticResult.get(account)
    let cumulativeClaimed: BigNumber | undefined

    let shouldFetchCumulativeClaimed = true
    if (persistedClaim.exists) {
      cumulativeClaimed =
        persistedClaim.data.airdropResult.data.cumulativeAmount
      shouldFetchCumulativeClaimed = false
    }
    let shouldFetchContractState = !contractsState
    let shouldFetchStaticData = !staticResult

    const contractStateCacheExpired =
      now - this.lastContractsStateFetch > this.contractStateCacheTTLMs

    if (contractStateCacheExpired) {
      shouldFetchContractState = true
    }

    if (staticResult && staticResult.data && contractsState) {
      if (
        staticResult.data.merkleRoot !== contractsState.merkleDrop.merkleRoot
      ) {
        // if there is a merkle root mismatch, fetch relevant data again
        shouldFetchContractState = true
        shouldFetchStaticData = true
      }
    }

    if (shouldFetchContractState || shouldFetchCumulativeClaimed) {
      const calls: Multicall3.Call3Struct[] = []
      calls.push({
        target: this.merkleDropAirdropAddress,
        allowFailure: false,
        callData:
          CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
            'merkleRoot'
          ),
      })
      calls.push({
        target: this.merkleDropAirdropAddress,
        allowFailure: false,
        callData:
          CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
            'claimIsOpen'
          ),
      })
      calls.push({
        target: this.merkleDropAirdropAddress,
        allowFailure: false,
        callData:
          CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
            'cumulativeClaimed',
            [account]
          ),
      })

      const [
        merkleRootAirdropResult,
        claimIsOpenAirdropResult,
        cumulativeClaimedResult,
      ] = await this.multicall.callStatic.tryAggregate(true, calls)

      const merkleRoot =
        CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
          'merkleRoot',
          merkleRootAirdropResult.returnData
        )[0]
      const claimIsOpen =
        CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
          'claimIsOpen',
          claimIsOpenAirdropResult.returnData
        )[0]
      cumulativeClaimed =
        CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
          'cumulativeClaimed',
          cumulativeClaimedResult.returnData
        )[0]

      contractsState = {
        merkleDrop: {
          claimIsOpen: claimIsOpen,
          merkleRoot: merkleRoot,
        },
        staking: { exists: true, kind: 'vault', isPaused: false },
      }
      this.contractsState = contractsState
      this.lastContractsStateFetch = now
    }

    if (shouldFetchStaticData) {
      staticResult = await this.staticAirdropService.airdrop(account)
      this.userToStaticResult.set(account, staticResult)
    }

    if (!cumulativeClaimed) {
      console.error('cumulative claimed not fetched')
      throw new Error('cumulative claimed not fetched')
    }

    if (!staticResult) {
      console.error('day one result not fetched')
      throw new Error('day one result not fetched')
    }

    const airdrop = reconstructAirdropFromStaticData({
      cumulativeClaimed,
      staticResult,
      persistedClaim,
    })

    if (!contractsState) {
      console.error('contract state not fetched')
      throw new Error('contract state not fetched')
    }

    return {
      airdrop,
      contractsState,
    }
  }
}

export async function fetchAirdropUser({
  account,
  merkleDropAirdrop,
  walletClient,
  vss,
}: {
  merkleDropAirdrop: MerkleDrop
  account: string
  walletClient: WalletClient
  vss: VoyageSignaturesService
}): Promise<AirdropResult> {
  const airdropDataP = walletClient.airdropChecker({ walletAddress: account })

  const calls: Multicall3.Call3Struct[] = []
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropAirdrop.address,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'cumulativeClaimed',
        [account]
      ),
  })

  // const [merkleRootResult, cumulativeClaimedResult] =
  //   await multicall.callStatic.tryAggregate(true, calls)

  // const currentMerkleRoot =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'merkleRoot',
  //     merkleRootResult.returnData
  //   )[0]
  // const cumulativeClaimed =
  //   CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
  //     'cumulativeClaimed',
  //     cumulativeClaimedResult.returnData
  //   )[0]

  const airdropData = await airdropDataP
  // if (prefix0x(airdropData.latestMerkleRootHex) !== currentMerkleRoot) {
  //   throw new Error('Merkle root mismatch')
  // }

  const pearls = airdropData.pearlAmount ?? 0

  if (airdropData.totalAmount === '' || airdropData.totalAmount === '0') {
    return {
      exists: false,
      pearls: pearls,
      isSybil: airdropData.isSybil,
    }
  }

  const loyaltyAmount = BigNumber.from(airdropData.loyaltyAmount)

  const selected = await vss.getSelectedOption(account)
  const selectedOption2 = selected === VoyageSignatureOption.Option2

  return {
    exists: true,
    // TODO: airdrop
    // data: {
    //   address: account,
    //   cumulativeAmount: BigNumber.from(airdropData.cumulativeAmount),
    //   merkleRoot: prefix0x(airdropData.latestMerkleRootHex),
    //   merkleProof: airdropData.proofsHex.map((p) => prefix0x(p)),
    //   totalAmount: BigNumber.from(airdropData.totalAmount),
    // },
    data: {
      address: account,
      cumulativeAmount: airdropData.isWhale
        ? BigNumber.from(0)
        : BigNumber.from(airdropData.totalAmount),
      merkleRoot: hexZeroPad('0x0', 32),
      merkleProof: [hexZeroPad('0x0', 32)],
      totalAmount: BigNumber.from(airdropData.totalAmount),
    },
    pearls,
    cumulativeClaimed: BigNumber.from(0), // TODO
    loyaltyAmount,
    isSybil: airdropData.isSybil,
    claimedEvents: [], // N/A for now
    // vestingTier: /* airdropData.vestingTier ?? 0, */ 0, // TODO
    vestingTier: airdropData.vestingTier ?? 0,
    selectedOption2,
  }
}

export async function fetchAirdropContractsState({
  merkleDropAirdropAddress,
  multicall,
}: {
  merkleDropAirdropAddress: string
  multicall: Multicall3
}): Promise<MerkleContractsState> {
  const calls: Multicall3.Call3Struct[] = []
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'merkleRoot'
      ),
  })
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'claimIsOpen'
      ),
  })
  calls.push({
    target: merkleDropAirdropAddress,
    allowFailure: false,
    callData:
      CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
        'stakingContract'
      ),
  })

  const [merkleRootAirdropResult, claimIsOpenAirdropResult, stakingContract] =
    await multicall.callStatic.tryAggregate(true, calls)

  const merkleRootAirdrop =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'merkleRoot',
      merkleRootAirdropResult.returnData
    )[0]
  const claimIsOpenAirdrop =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'claimIsOpen',
      claimIsOpenAirdropResult.returnData
    )[0]
  const stakingContractAddress =
    CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
      'stakingContract',
      stakingContract.returnData
    )[0]

  const stakingExists = stakingContractAddress !== ethers.constants.AddressZero

  return {
    merkleDrop: {
      claimIsOpen: claimIsOpenAirdrop,
      merkleRoot: merkleRootAirdrop,
    },
    staking: stakingExists
      ? { exists: true, kind: 'vault', isPaused: false }
      : { exists: false },
  }
}
