import { useSwellWeb3 } from '@/swell-web3/core'
import { useSwellDaoApi } from './context'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import useSWRImmutable from 'swr/immutable'
import { merkleDropClaimAndLockGasEstimate } from '@/constants/gasEstimates'
import { ethers } from 'ethers'

export function useSwellDaoToken() {
  return useSwellDaoApi().daoToken
}
export function useRestakedSwellDaoToken() {
  return useSwellDaoApi().restakedDaoToken
}
export function usePersistedAirdropClaim() {
  const api = useSwellDaoApi()
  return api.persistedClaim()
}
export function useSwellDaoTokens() {
  const { daoToken, restakedDaoToken } = useSwellDaoApi()
  return { daoToken, restakedDaoToken }
}

export function useAirdropPersistence() {
  const api = useSwellDaoApi()
  const onClaimDaoToken = api.onClaimDaoToken

  return { onClaimDaoToken }
}

export function usePersistedAirdropClaimApi() {
  const api = useSwellDaoApi()
  return {
    clearRecentDaoTokenClaim: api.clearRecentDaoTokenClaim,
    onClaimDaoToken: api.onClaimDaoToken,
    persistedClaim: api.persistedClaim,
    persistedClaimForAddress: api.persistedClaimForAddress,
  }
}

function useAirdropAll() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(
    account ? ['airdrop-all', account] : null,
    () => {
      return api.read.airdropAll()
    },
    { revalidateOnMount: true }
  )
}

export function useSwellDaoAirdrop() {
  const query = useAirdropAll()
  return {
    data: query.data?.airdrop,
    isLoading: query.isLoading,
    error: query.error,
    mutate: query.mutate,
    isValidating: query.isValidating,
  }
}

export function useSwellDaoBalances() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['balances', account] : null, () => {
    return api.read.balances()
  })
}
export function useSwellDaoWavedrop() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['wavedrop', account] : null, () => {
    return api.read.wavedrop()
  })
}
export function useSwellDaoWavedrop2() {
  const { account } = useSwellWeb3()
  const api = useSwellDaoApi()
  return useSWRImmutable(account ? ['wavedrop2', account] : null, () =>
    api.read.wavedrop2()
  )
}
export function useSwellDaoWaveDropStats() {
  const api = useSwellDaoApi()
  return useSWRImmutable(['wavedrop-stats'], () => {
    return api.read.waveDropStats()
  })
}

export function useSwellDaoAirdropContractsState() {
  const query = useAirdropAll()
  return {
    data: query.data?.contractsState,
    isLoading: query.isLoading,
    error: query.error,
    mutate: query.mutate,
    isValidating: query.isValidating,
  }
}
export function useSwellDaoAirdropOffchainState() {
  const api = useSwellDaoApi()
  return useSWRImmutable(['airdrop-offchain-state'], () => {
    return api.read.airdropOffchainState()
  })
}
export function useSwellDaoClaimAirdrop() {
  const cumulativeClaimed = useSwellDaoAirdrop()?.data?.cumulativeClaimed
  const { write, read } = useSwellDaoApi()
  return useWeb3Call({
    fn: write.claimAirdrop,
    estimateGas: write.claimAirdropEstimateGas,
    staticGasEstimate: ({ amountToLock, cumulativeAmount }) => {
      return merkleDropClaimAndLockGasEstimate({
        amountToLock,
        cumulativeAmount,
        cumulativeClaimed: cumulativeClaimed ?? ethers.constants.Zero,
        lockType: 'yearn',
      })
    },
    validate: async ({ amountToLock, cumulativeAmount, merkleProof }) => {
      const valid = await read.checkClaimAirdrop({
        cumulativeAmount,
        amountToLock,
        merkleProof,
      })

      if (!valid) {
        return 'Invalid merkle proof'
      }

      return null
    },
  })
}
export type SwellDaoClaimAirdrop = ReturnType<typeof useSwellDaoClaimAirdrop>
