import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { SwellDaoContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { AirdropOffchainState, IDaoApiRead, IDaoApiWrite } from './types'
import {
  useIERC20Contract,
  useMerkleDropContract,
  useMulticallContract,
} from '@/hooks/useContract'
import { Token } from '@/types/tokens'
import { AIRDROP_VESTING_DATES } from '@/constants/airdrops'
import { useAirdropPersistenceApi } from './persistanceApi'
import { StaticAirdropFetcher } from './fetchers'
import { useMemo, useRef } from 'react'
import { StaticAirdropService } from '@/services/StaticAirdrop'
import { useDaoSettings } from '../deployments/hooks/daoSettings'
import { MerkleDrop } from '@/types/merkle'

export function useDaoApiImpl({
  merkleDropAirdrop,
  daoToken,
  restakedDaoToken,
}: {
  daoToken: Token
  restakedDaoToken: Token
  merkleDropAirdrop: MerkleDrop
}): SwellDaoContext {
  const persistenceApi = useAirdropPersistenceApi()
  const { staticAirdropUrl } = useDaoSettings()

  const staticAirdropService = useMemo(
    () => new StaticAirdropService({ baseURL: staticAirdropUrl }),
    [staticAirdropUrl]
  )
  const mc = useMulticallContract()!
  const { account } = useSwellWeb3()

  const staticSvcRef = useRef<StaticAirdropFetcher | undefined>()
  const accRef = useRef<string | undefined>(account)
  const staticSvc = useMemo(() => {
    let svc = staticSvcRef.current
    const accountChanged = accRef.current !== account

    if (!svc || accountChanged) {
      if (account) {
        svc = new StaticAirdropFetcher({
          staticAirdropService,
          merkleDropAirdropAddress: merkleDropAirdrop.address,
          multicall: mc,
          persistenceApi,
        })
        staticSvcRef.current = svc
      }
    }

    accRef.current = account
    return svc
  }, [
    staticAirdropService,
    merkleDropAirdrop.address,
    mc,
    persistenceApi,
    account,
  ])

  return {
    ...persistenceApi,
    daoToken,
    restakedDaoToken,
    merkleDropAirdrop,
    read: useDaoApiRead({
      daoToken,
      merkleDropAirdrop,
      staticSvc,
    }),
    write: useDaoApiWrite({
      merkleDropAirdrop,
    }),
  }
}

async function fetchAirdropOffchainState(): Promise<AirdropOffchainState> {
  return { ...AIRDROP_VESTING_DATES }
}

function useDaoApiRead({
  daoToken,
  merkleDropAirdrop,
  staticSvc,
}: {
  daoToken: Token
  merkleDropAirdrop: MerkleDrop
  staticSvc: StaticAirdropFetcher | undefined
}): IDaoApiRead {
  const { account: maybeAccount } = useSwellWeb3()
  const { v3BackendLrtUrl, v3BackendLstUrl } = useDeploymentSetConfig()
  const walletClientLRT =
    useV3BackendClient(v3BackendLrtUrl).v3BackendClient.wallet

  const statsClient = useV3BackendClient(v3BackendLstUrl).v3BackendClient.stats
  const merkleDropContractAirdrop = useMerkleDropContract(
    merkleDropAirdrop.address
  )!

  const swellTokenContract = useIERC20Contract(daoToken.address)!

  const account = maybeAccount!
  return {
    balances: async () => {
      return {
        swellBalance: await swellTokenContract.balanceOf(account),
      }
    },
    airdropAll: async () => {
      if (!staticSvc) throw new Error('No service')
      return staticSvc.fetchAll(account)
    },

    // airdrop: async () => {
    //   if (!account) {
    //     throw new Error('No account')
    //   }

    //   return fetchAirdropUser({
    //     account,
    //     merkleDropAirdrop,
    //     walletClient: walletClientLRT,
    //     persistenceApi,
    //   })
    // },
    // airdropContractsState: async () => {
    //   return fetchAirdropContractsState({
    //     merkleDropAirdropAddress: merkleDropAirdrop.address,
    //     multicall,
    //   })
    // },
    airdropOffchainState: async () => {
      return fetchAirdropOffchainState()
    },
    wavedrop: async () => {
      const { blackPearls, multiplier } = await walletClientLRT.wavedropUser({
        walletAddress: account,
      })
      return { blackPearls, multiplier }
    },
    wavedrop2: async () => {
      const { blackPearls, multiplier } = await walletClientLRT.wavedrop2User({
        walletAddress: account,
      })
      return { blackPearls, multiplier }
    },
    waveDropStats: async () => {
      const { currentWaveNumber, nextWavedropEndUnix } =
        await statsClient.wavedropStats({})
      return { nextWavedropEndUnix, currentWaveNumber }
    },
    checkClaimAirdrop: async ({ merkleProof, cumulativeAmount }) => {
      return merkleDropContractAirdrop.verifyProof(
        merkleProof,
        cumulativeAmount,
        account
      )
    },
  }
}

function useDaoApiWrite({
  merkleDropAirdrop,
}: {
  merkleDropAirdrop: MerkleDrop
}): IDaoApiWrite {
  const merkleDropAirdropContract = useMerkleDropContract(
    merkleDropAirdrop.address
  )!

  const { account: maybeAccount } = useSwellWeb3()
  return {
    claimAirdrop: async (
      { amountToLock, cumulativeAmount, merkleProof },
      opts
    ) => {
      return merkleDropAirdropContract.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof,
        opts
      )
    },
    claimAirdropEstimateGas: async ({
      amountToLock,
      cumulativeAmount,
      merkleProof,
    }) => {
      return merkleDropAirdropContract.estimateGas.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof
      )
    },
  }
}
